/* eslint-disable vue/no-unused-components */
<template>
  <div class="deal-maker-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-9">
              <div class="heading-profile">
                <h3>
                  Deal Maker &amp; Hire an Expert
                </h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Services
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForAllHireExpert(page)"
                          >Download</b-button
                        >
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewHireExpertNDeals(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>User Name</th>
                          <th>Date of Purchase Service</th>
                          <th>
                            <b-dropdown :text="selected1 ? selected1.text : 'Service'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="selectedService(option)"
                                v-for="option in service"
                                :key="option.value"
                              >
                                <div class="text">
                                  {{ option.text }}
                                </div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>
                          <th>Person Assign Details</th>
                          <th>
                            <!-- <b-dropdown :text="selected2 ? selected2.text : 'status'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="select2(option)"
                                v-for="option in status"
                                :key="option.value"
                              >
                                <div class="text">
                                  {{ option.text }}
                                </div>
                              </b-dropdown-item>
                            </b-dropdown> -->
                          </th>
                          <th>City</th>
                          <th>State</th>
                          <th>Country</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in HireExpertNDeals" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.createdAt | moment("DD/MM/YYYY") }}</div>
                          </td>
                          <td>{{ item.type }}</td>
                          <td v-if="item.personAssignDetails">{{ item.personAssignDetails }}</td>
                          <td v-else></td>
                          <td v-if="item.status">{{ item.status }}</td>
                          <td v-else></td>
                          <td>
                            <div>
                              {{ item.user ? item.user.city : "" }}
                            </div>
                          </td>
                          <td>
                            <div>{{ item.state ? item.user.state : "" }}</div>
                          </td>
                          <td>
                            <div>{{ item.country ? item.user.country : "" }}</div>
                          </td>
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="{ name: 'viewDetails', params: { id: item._id } }"
                              append
                            >
                              <!-- <font-awesome-icon :icon="['fas', 'edit']" /> -->
                              <b-icon-eye></b-icon-eye>
                            </router-link>
                          </td>
                        </tr>
                        <!-- <div>
                          <deletemodal
                            :mymodal="blogData"
                            v-on:conformModal="deleteBlogs"
                          ></deletemodal>
                        </div> -->
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="HireExpertNDeals.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="HireExpertNDeals.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewHireExpertNDeals(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import deletemodal from "@/components/deletemodal.vue";
import moment from "moment";

export default {
  components: {
    headerCustom
    // deletemodal
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      blogData: {},
      HireExpertNDeals: [],
      selected1: null,
      selected2: null,
      service: [
        {
          text: "All",
          value: "All"
        },
        {
          text: "Hire Expert",
          value: "HireExpert"
        },
        {
          text: "Deal Maker",
          value: "DealMaker"
        }
      ],
      status: [
        {
          text: "Verified",
          value: "verified"
        },
        {
          text: "Not Verified",
          value: "notverified"
        }
      ]
    };
  },
  created() {
    this.viewHireExpertNDeals(this.page);
  },
  methods: {
    viewHireExpertNDeals(pageNo) {
      this.HireExpertNDeals = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewHireExpertNDeals(this.formData, data => {
        if (data.code === 200) {
          this.HireExpertNDeals = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    generateExcelForAllHireExpert() {
      service.generateExcelForAllHireExpert({}, data => {
        service.downloadExcel(data, "HireExpert_DealMaker_List");
      });
    },
    selectedService(option) {
      this.selected1 = option;

      if (option.text == "All") {
        this.formData.selectedType = option.value;
        this.formData.type = "";
      } else {
        this.formData.type = option.value;
        this.formData.selectedType = "";
      }
      this.viewHireExpertNDeals(this.page);
    },
    select2(option) {
      this.selected2 = option;
    }
    // deleteBlogById(user) {
    //   this.blogData = user;
    // },
    // deleteBlogs(blog) {
    //   service.deleteBlogs(blog._id, result => {
    //     if (result.code == 200) {
    //       this.$toaster.success("Blogs Deleted");
    //       this.viewBlogList(1);
    //     } else {
    //       this.$toaster.errors("Something went wrong");
    //     }
    //   });
    // }
  }
};
</script>

<style lang="scss">
.deal-maker-section {
  .text {
    color: black;
  }
  .btn-secondary {
    background: transparent;
    border-color: transparent;
    color: black;
    box-shadow: none;
    font-weight: 700;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
      color: black;
      box-shadow: none;
    }
  }
  .table .btn {
    padding: 0px 6px;
  }
}
</style>
